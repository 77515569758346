<template>
  <div></div>
</template>
<script setup>
import { logout } from "@/utilities";
import ROUTER from "@/router";
/**
 * This is the Auth0 callback page
 */
if (ROUTER.currentRoute.value.query.error) {
  /**
   * If any error occurred during Authentication and Auth0 didn't show the 
   * Auth0 error page, then we will log them out and gets redirected to a
   * Preava error page
   */
  logout({ returnTo: `${location.protocol}//${location.host}/error?type=default&code=&message=${encodeURI('Authentication Error')}&details=${encodeURI(ROUTER.currentRoute.value.query.error)}` });
} else {
  /**
   * Otherwise, we will proceed with Activation
   */
  ROUTER.push({ path: '/activate' })
}
</script>
